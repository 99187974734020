// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '150'
  },{
    prop: 'name',
    label: '',
    Object: 'value',
    width: '150'
  },{
    prop: 'updated_at',
    label: '',
    Object: 'value',
    width: '100',
  }]
}
